import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://97b8597b21b2f14cc70b44e2e9dd6783@o4508268639682560.ingest.de.sentry.io/4508268641845328',
	environment: import.meta.env.MODE,
	enabled: import.meta.env.MODE === 'production'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
