import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [44,[9]],
		"/(home)/404": [45,[9]],
		"/(app)/(dashboard)/account": [13,[2,3,4]],
		"/(app)/(dashboard)/account/security": [14,[2,3,4]],
		"/(app)/auth/confirm-email": [35,[2]],
		"/(app)/auth/debug": [36,[2]],
		"/(app)/auth/join": [37,[2]],
		"/(app)/auth/login": [38,[2]],
		"/(app)/auth/reset-password": [39,[2]],
		"/(app)/auth/set-new-password": [40,[2]],
		"/(app)/auth/signup": [41,[2]],
		"/(app)/auth/sso/error": [43,[2]],
		"/(app)/auth/sso/[[organizationId]]": [42,[2]],
		"/(home)/blog": [46,[9,10]],
		"/(home)/blog/[...slug]": [47,[9,10]],
		"/(app)/(dashboard)/checkout": [15,[2,3]],
		"/(home)/check": [48,[9]],
		"/(home)/compare/dmarcadvisor": [49,[9]],
		"/(home)/compare/dmarcian": [50,[9]],
		"/(home)/compare/postmark-dmarc": [51,[9]],
		"/(home)/contact": [52,[9]],
		"/(home)/diagnoses/[token]": [53,[9]],
		"/(home)/docs/[...slug]": [54,[9,11]],
		"/(app)/(dashboard)/domains": [16,[2,3]],
		"/(app)/(dashboard)/domains/[name]": [17,[2,3,5]],
		"/(app)/(dashboard)/domains/[name]/diagnoses": [18,[2,3,5]],
		"/(app)/(dashboard)/domains/[name]/diagnoses/[token]": [19,[2,3,5]],
		"/(app)/(dashboard)/domains/[name]/dns-history": [20,[2,3,5]],
		"/(app)/(dashboard)/domains/[name]/reports": [21,[2,3,5,6]],
		"/(app)/(dashboard)/domains/[name]/reports/sources/[hostname]": [22,[2,3,5,6]],
		"/(app)/(dashboard)/domains/[name]/settings": [23,[2,3,5]],
		"/(home)/features": [55,[9]],
		"/(app)/(dashboard)/internal": [24,[2,3,7]],
		"/(app)/(dashboard)/internal/domains": [25,[2,3,7]],
		"/(app)/(dashboard)/internal/emails/[[emailId]]": [26,[2,3,7]],
		"/(app)/(dashboard)/internal/organizations": [27,[2,3,7]],
		"/(home)/learn": [56,[9]],
		"/(home)/learn/[...slug]": [57,[9,12]],
		"/(app)/(dashboard)/onboarding": [28,[2,3]],
		"/(home)/pricing": [58,[9]],
		"/(home)/privacy-policy": [59,[9]],
		"/(home)/resources": [60,[9]],
		"/(app)/(dashboard)/settings": [29,[2,3,8]],
		"/(app)/(dashboard)/settings/billing": [30,[2,3,8]],
		"/(app)/(dashboard)/settings/billing/upgrade": [31,[2,3]],
		"/(app)/(dashboard)/settings/members": [32,[2,3,8]],
		"/(app)/(dashboard)/settings/sso": [33,[2,3,8]],
		"/(app)/(dashboard)/support": [34,[2,3]],
		"/(home)/terms-of-service": [61,[9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';